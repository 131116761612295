import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios"; //引入axios
import "./permission"; // permission control
import "@/styles/index.scss"; // global css
import "./icons"; // icon
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";

import "./styles/element-variables.scss";

// import 'default-passive-events' // 解决浏览器警告 Added non-passive event listener to a scroll-blocking <some> event. Consider marking event handler as 'passive' to make the page more responsive.
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios; //添加axios到Vue的原型对象上
Vue.prototype.dayjs = dayjs; //可以全局使用dayjs
Vue.prototype.signalR = signalR;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
