<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created(){
    let isclose=0;
    let timer=setInterval(() => {
      if(isclose==2)
      {
        clearInterval(timer)
      }
        for(let i = 0; i<document.querySelectorAll('span').length;i++)
       {
      if(document.querySelectorAll('span')[i].innerText=="客户报价历史")
       {
         isclose++;
         document.querySelectorAll('span')[i].parentElement.parentElement.parentElement.style.display="none"
       }
      else if(document.querySelectorAll('span')[i].innerText=="客户订购历史")
      {
        isclose++;
         document.querySelectorAll('span')[i].parentElement.parentElement.parentElement.style.display="none"
      }
      }
    },1000)
  
  }
}
</script>
