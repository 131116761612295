import request from "@/utils/request";

//登录
export function login(data) {
  return request({
    url: "/vue-element-admin/user/login",
    method: "post",
    data
  });
}

//单点登录
export function singlelogining(paramObj) {
  return request({
    url: "/Account/SignLogin",
    method: "get",
    params: { titck: paramObj.userInfoStr }
  });
}

export function getInfo() {
  return request({
    url: "/Account/CurrentUser",
    method: "get"
  });
}

export function logout() {
  return request({
    url: "/Account/Logout",
    method: "get"
  });
}

// 菜单-菜单列表
export function getMenuList() {
  return request({
    url: "/Privilege/GetCrmMenu",
    method: "get"
  });
}

// 获取该用户有权限的用户
export function getAuthorityUserList() {
  return request({
    url: "/Privilege/GetAuthorityUserList",
    method: "get"
  });
}
