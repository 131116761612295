import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true
	},
	{
		//单点登录
		path: '/signLogin',
		component: () => import('@/views/login/single'),
		hidden: true
	},
	{
		//企业微信打卡
		path: '/wechat/clockin/list',
		component: () => import('@/views/wechat/clockin/list'),
		hidden: true
	},	
	{
		//企业微信打卡
		path: '/wechat/clockin',
		component: () => import('@/views/wechat/clockin/index'),
		hidden: true
	},
	{
		//企业微信打卡
		path: '/wechat/near',
		component: () => import('@/views/wechat/clockin/near'),
		hidden: true
	},	
	{
		path: '/',
		component: Layout,
		redirect: '/dashboard',
		children: [
			{
				path: 'dashboard',
				component: () => import('@/views/dashboard/index'),
				name: '欢迎使用',
				meta: { title: '欢迎使用', icon: 'dashboard', affix: true }
			}
		]
	},
	{
		path: "/wechat/visit/visitList",
		component: () => import("@/views/wechat/visit/visitList"),
		hidden: true,
	},
	{
		path: "/wechat/home/index",
		component: () => import("@/views/wechat/home/index"),
		hidden: true,
	},
	{
		path: "/wechat/home/menu",
		component: () => import("@/views/wechat/home/menu"),
		hidden: true,
	},
	{
		path: "/wechat/customer/customerList",
		component: () => import("@/views/wechat/customer/customerInfo/customerList"),
		hidden: true,
	},
	{
		path: "/wechat/customer/companyCustomerDetail",
		component: () => import("@/views/wechat/customer/customerInfo/companyCustomerDetail"),
		hidden: true,
	},
	{
		path: "/wechat/customer/individualCustomerDetail",
		component: () => import("@/views/wechat/customer/customerInfo/individualCustomerDetail"),
		hidden: true,
	},
	{
		path: "/wechat/customer/mymoooGoods",
		component: () => import("@/views/wechat/customer/customerInfo/mymoooGoods"),
		hidden: true,
	},
	{
		path: "/wechat/customer/customerDailyQuery",
		component: () => import("@/views/wechat/customer/customerVisit/customerDailyQuery"),
		hidden: true,
	},
	{
		path: "/wechat/customer/customerDailyDetail",
		component: () => import("@/views/wechat/customer/customerVisit/customerDailyDetail"),
		hidden: true,
	},
	{
		path: "/wechat/customer/customerVisitDetail",
		component: () => import("@/views/wechat/customer/customerVisit/customerVisitDetail"),
		hidden: true,
	},
	{
		path: "/wechat/customer/customerDailyEdit",
		component: () => import("@/views/wechat/customer/customerVisit/customerDailyEdit"),
		hidden: true,
	},
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = []

const createRouter = () =>
	new Router({
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes
	})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

//解决Redirected from “xxx“ to “xxx“ via a navigation guard报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

export default router
