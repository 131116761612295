import { asyncRoutes, constantRoutes } from '@/router';
import { getMenuList } from '@/api/user';
import { flatMultiLevelRoutes } from '@/utils/routeHelper';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
	if (route.meta && route.meta.roles) {
		return roles.some((role) => route.meta.roles.includes(role));
	} else {
		return true;
	}
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
	const res = [];

	routes.forEach((route) => {
		const tmp = { ...route };
		if (hasPermission(roles, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, roles);
			}
			res.push(tmp);
		}
	});
	return res;
}

// 递归修改返回的路由component路径
export function filterRoutes(routes, level = 0) {
	const res = [];
	routes.forEach((route) => {
		const tmp = { ...route };
		if (!tmp.children) {
			let aa = tmp['component'];
			tmp['component'] = () => import(`@/views${aa}`);
		} else {
			let bb = tmp['component'];
			// tmp['component'] = () => import(`@/layout${bb}`)
			// tmp.children = filterRoutes(tmp.children)

			if (level === 0) {
				tmp['component'] = () => import(`@/layout${bb}`);
			} else {
				tmp['component'] = () => import(`@/views${bb}`);
			}
			level++;
			tmp.children = filterRoutes(tmp.children, level);
			level--;
		}
		res.push(tmp);
	});
	return res;
}

const state = {
	routes: [],
	addRoutes: [],
	temArr: [],
};

const mutations = {
	SET_ROUTES: (state, routes) => {
		state.addRoutes = routes;
		state.routes = constantRoutes.concat(routes);
	},
	RESET_ROUTES: (state) => {
		state.routes = [];
	},
};

const actions = {
	generateRoutes({ commit }) {
		return new Promise((resolve) => {
			let accessedRoutes;
			getMenuList().then((res) => {
				if (res.code == 'success') {
					// res.data.push({
					// 	path: '/creditManage',
					// 	component: '/index',
					// 	// alwaysShow: true,
					// 	sort: 40,
					// 	meta: {
					// 		title: '信用管理',
					// 		icon: 'documentation'
					// 	},
					// 	children: [
					// 		{
					// 			path: 'creditlist',
					// 			component: '/creditManage/creditlist/index',
					// 			name: 'creditlist',
					// 			sort: 10,
					// 			meta: {
					// 				title: '信用总表',
					// 				icon: ''
					// 			}
					// 		},
					// 		{
					// 			path: 'mycreditlist',
					// 			component: '/creditManage/mycreditlist/index',
					// 			name: 'mycreditlist',
					// 			sort: 10,
					// 			meta: {
					// 				title: '信用列表',
					// 				icon: ''
					// 			}
					// 		},
					// 		{
					// 			path: 'creditapplyrecordlist',
					// 			component: '/creditManage/creditapplyrecordlist/index',
					// 			name: 'creditapplyrecordlist',
					// 			sort: 10,
					// 			meta: {
					// 				title: '信用申请记录总表',
					// 				icon: ''
					// 			}
					// 		},
					// 		{
					// 			path: 'mycreditapplyrecord',
					// 			component: '/creditManage/mycreditapplyrecord/index',
					// 			name: 'mycreditapplyrecord',
					// 			sort: 10,
					// 			meta: {
					// 				title: '信用申请记录列表',
					// 				icon: ''
					// 			}
					// 		}
					// 	]
					// })
					if (res.data && res.data.length > 0) {
						res.data.forEach((item) => {
							item.alwaysShow = true;
						});
						res.data.push(
							res.data.splice(
								res.data.findIndex((item) => item.meta.title === '系统管理'),
								1,
							)[0],
						);
					}

					accessedRoutes = filterRoutes(res.data);
					setTimeout(() => {
						commit('SET_ROUTES', accessedRoutes);
						let reallyRoutes = flatMultiLevelRoutes(accessedRoutes);
						resolve(reallyRoutes);
					}, 1000);
					// setTimeout(() => {
					// 	commit('SET_ROUTES', accessedRoutes)
					// 	resolve(accessedRoutes)
					// }, 1000)
				}
			});
		});
	},
	// 清空路由表
	resetRoutes({ commit }) {
		return new Promise((resolve) => {
			commit('RESET_ROUTES');
			resolve();
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
