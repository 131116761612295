import { login, singlelogining, logout, getInfo, getAuthorityUserList } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import Cookies from 'js-cookie'

const state = {
	token: getToken(),
	userCode: '',
	userName: '',
	avatar: '',
	introduction: '',
	isAdmin: false,
	isKeeponAttache: false,
	userId: null,
	mymoooCompany: '',
	authorityUser: []
	// roles: []
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_INTRODUCTION: (state, introduction) => {
		state.introduction = introduction
	},
	SET_USERCODE: (state, userCode) => {
		state.userCode = userCode
	},
	SET_USERNAME: (state, userName) => {
		state.userName = userName
	},
	SET_ISADMIN: (state, isAdmin) => {
		state.isAdmin = isAdmin
	},
	SET_ISKEEPONATTACHE: (state, isKeeponAttache) => {
		state.isKeeponAttache = isKeeponAttache
	},
	SET_USERID: (state, userId) => {
		state.userId = userId
	},
	SET_MYMOOOCOMPANY: (state, mymoooCompany) => {
		state.mymoooCompany = mymoooCompany
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_AUTHORITYUSER: (state, authorityUser) => {
		state.authorityUser = authorityUser
	}
	// SET_ROLES: (state, roles) => {
	//   state.roles = roles
	// }
}

const actions = {
	// 用户登录
	login({ commit, dispatch }, userInfo) {
		const { username, password } = userInfo
		return new Promise((resolve, reject) => {
			login({ username: username.trim(), password: password })
				.then(response => {
					const { data } = response
					commit('SET_TOKEN', data.token)
					dispatch('permission/resetRoutes', {}, { root: true })
					resetRouter()
					setToken(data.token)
					resolve()
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// 单点登录
	singlelogin({ commit, dispatch }, userInfoStr) {
		return new Promise((resolve, reject) => {
			singlelogining({ userInfoStr })
				.then(response => {
					let dataDt = response
					commit('SET_TOKEN', dataDt.data)
					dispatch('permission/resetRoutes', {}, { root: true })
					resetRouter()
					setToken(dataDt.data)
					resolve()
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// get user info
	getInfo({ commit }) {
		//Cookies.set(
		//	'Cookies',
		//	'1zF+lQ9pNHpOQpqsWbM68z5y2MIqUt+UyE4UQi7GJeRANtR8CsKPV967BxURJ8BiYamDow/i7RoYnw8AzL7g203xxj2A5X5/kF2fMuvM8YfdB4K5kj8xH5fvbnv8jRI/3ixVDFmHN4SRPcx0JqYeuJABchQNet3qtdUc9nywet83ZNUrDIR8lqtE6zHnsz/wHry+FcJ5M0E4WqKxP297FT/nW+dloA57HceDDyXZPhUTqjd6iDxaJ/hmi6QAQgZ6FjbUSjhOebNWbTg0Bzk49hpDZm5a9IjlO62/i6jPB1F5QUTSY9U/KHEYkD/6qW49jx0QT+eEq8ko862sg3Tw5VL2NHOzhcu9IbaeV8E7EuPJe3CqUMmvjspeN8Pb1i113vHFVRwHJN+U8ygj+2ZuR05YT4/uInUXYf9fUh0mKBi3N8zOan9rYGEMgngOoABgXGQ9CyV1tjcIIpJsU5+vjkI1IKLLzKt2FY8dQjaWn2ym3yAiOZMhLNF/KgHtgvG+'
		//)
		return new Promise((resolve, reject) => {
			getInfo().then(response => {
				if (response.code === 'success') {
					const { data } = response
					const { code, name, isAdmin, userId, mymoooCompany, isKeeponAttache } = data

					commit('SET_USERCODE', code)
					commit('SET_USERNAME', name)
					commit('SET_ISADMIN', isAdmin)
					commit('SET_ISKEEPONATTACHE', isKeeponAttache)
					commit('SET_USERID', userId)
					commit('SET_MYMOOOCOMPANY', mymoooCompany)
					Cookies.set('userName', name)
					resolve(data)
				} else {					
					location.href = '/Account/ScanLogin?redirectUri=' + window.location.href.split('/#')[1]
				}
			})
			// .catch(error => {
			// 	reject(error)
			// })
		})
	},

	getAuthorityUserList({ commit }) {
		return new Promise((resolve, reject) => {
			getAuthorityUserList().then(response => {
				if (response.code === 'success' && response.data) {
					const { data } = response
					commit('SET_AUTHORITYUSER', data)
					resolve(data)
				}
			})
		})
	},

	// user logout
	logout({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			logout(state.token)
				.then(() => {
					commit('SET_TOKEN', '')
					commit('SET_USERCODE', '')
					commit('SET_USERNAME', '')
					Cookies.remove('userName')
					// 清空路由表
					dispatch('permission/resetRoutes', {}, { root: true })
					removeToken()
					resetRouter()

					// reset visited views and cached views
					// to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
					dispatch('tagsView/delAllViews', null, { root: true })

					resolve()
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	// remove token
	resetToken({ commit }) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '')
			// commit("SET_ROLES", [])
			removeToken()
			resolve()
		})
	},

	// dynamically modify permissions
	async changeRoles({ commit, dispatch }, role) {
		const token = role + '-token'

		commit('SET_TOKEN', token)
		setToken(token)

		const { roles } = await dispatch('getInfo')

		resetRouter()

		// generate accessible routes map based on roles
		const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
		// dynamically add accessible routes
		router.addRoutes(accessRoutes)

		// reset visited views and cached views
		dispatch('tagsView/delAllViews', null, { root: true })
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
